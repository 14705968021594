jQuery(function($) {

  // Smooth scroll - Add class = "smooth-scroll" to <a>
  $('a.smooth-scroll[href^="#"]').click(function(e) {
    e.preventDefault()
    let headerHeight = 0
    const win = $(window).width()
    if (win < 980) {
      headerHeight = 60
    }
    const speed = 400
    const href = jQuery(this).attr('href')
    const target = jQuery(href === '#' || href === ''
      ? 'html'
      : href)
    const position = target.offset().top - headerHeight
    $('body,html').animate({ scrollTop: position }, speed, 'swing')
    return false
  })

  // Back to top button - Set display on scroll amount
  const scrollWindows = 200
  $(window).on('scroll', function() {
    if ($(this).scrollTop() > scrollWindows) {
      $('#back-to-top').addClass('display')
    } else {
      $('#back-to-top').removeClass('display')
    }
  })

  // Everything with class '.h-line' will be rendered in same height
  $(document).ready(function($) {
    $('html').imagesLoaded(function() {
      $('.h-line').matchHeight()
    })
  })

  // Menu
  $('.accordion-menu .toggle').on('click', function(e) {
    e.preventDefault()
    const parent = $(this).closest('.menu-item')
    if (parent.hasClass('is-open')) {
      $(this).next().slideUp(250)
      $(this).removeClass('is-clicked')
      parent.removeClass('is-open')
      return
    }
    $(this).next().slideDown(250)
    $(this).addClass('is-clicked')
    parent.addClass('is-open')
  })
  const closeMenu = () => {
    $('#menu-drawer').removeClass('is-open')
    setTimeout(() => {
      $('html').css({
        'overflow-y': 'auto'
      })
    }, 100)
  }
  $('#menu-sp-btn').on('click', function(e) {
    e.preventDefault()
    $('#menu-drawer').addClass('is-open')
    setTimeout(() => {
      $('html').css({
        'overflow-y': 'hidden'
      })
    }, 200)
  })
  $('#menu-drawer .close-zone .close-button').on('click', function() {
    closeMenu()
  })
  $('body').on('click', function(e) {
    if (
      !$(e.target).closest('#menu-drawer').length &&
      !$(e.target).is('#menu-drawer') &&
      !$(e.target).closest('#menu-sp-btn').length &&
      !$(e.target).is('#menu-sp-btn')
    ) {
      closeMenu()
    }
  })

  // Custom dropdown
  $('select.custom-select').each(function() {
    let $this = $(this),
      numberOfOptions = $(this).children('option').length
    $this.addClass('s-hidden')
    $this.wrap('<div class="select"></div>')
    $this.after('<div class="styledSelect"></div>')
    let $styledSelect = $this.next('div.styledSelect')
    $styledSelect.text(
      $this
        .children('option')
        .eq(0)
        .text()
    )
    let $list = $('<ul />', {
      class: 'options'
    }).insertAfter($styledSelect)
    for (let i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this
          .children('option')
          .eq(i)
          .text(),
        rel: $this
          .children('option')
          .eq(i)
          .val()
      }).appendTo($list)
    }
    let $listItems = $list.children('li')
    $styledSelect.click(function(e) {
      e.stopPropagation()
      $('div.styledSelect.active').each(function() {
        $(this)
          .removeClass('active')
          .next('ul.options')
          .hide()
      })
      $(this)
        .toggleClass('active')
        .next('ul.options')
        .toggle()
    })
    $listItems.click(function(e) {
      e.stopPropagation()
      $(this).addClass('selected').siblings().removeClass('selected')
      $styledSelect.text($(this).text()).removeClass('active')
      $this.val($(this).attr('rel'))
      $list.hide()
    })
    $(document).click(function() {
      $styledSelect.removeClass('active')
      $list.hide()
    })
  })

  // Tags
  $('.tags-selection .tags').on('click', function() {
    $(this).toggleClass('selected')
  })

  // Main header
  const headerHeight = $('#main-header').outerHeight()
  $('main').css({
    marginTop: headerHeight
  })

})
